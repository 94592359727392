import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import Mixin from "@/utils/mixin";
import { searchKeys, columns } from "./config/index";
import { WhitelistApi } from "@/api";
import AddWhitelist from "./component/AddWhitelist";
var statusList = [{
  id: '',
  name: '全部'
}, {
  id: '1',
  name: '正常'
}, {
  id: '2',
  name: '停用'
}];
export default {
  name: "whitelistManagement",
  components: {
    AddWhitelist: AddWhitelist
  },
  data: function data() {
    return {
      loading: false,
      searchKeys: searchKeys,
      createForm: this.$form.createForm(this, {
        name: "whitelist_index"
      }),
      createFormValues: {},
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      isDeleteVisible: false,
      deleting: false,
      dataSource: [],
      tipsDesc: '',
      modalTitle: '添加白名单用户',
      modalVisible: false
    };
  },
  mixins: [Mixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        switch (key) {
          case 'status':
            return statusList;
        }
      };
    },
    coverImg: function coverImg() {
      return function (val) {
        if (val.indexOf(",") == -1) {
          return val;
        } else {
          return val.split(",")[0];
        }
      };
    },
    splitImg: function splitImg() {
      return function (record) {
        if (record.imgurls.indexOf(",") !== -1) {
          return record.imgurls.split(',')[0];
        } else if (record.imgurls.indexOf("|") !== -1) {
          return record.imgurls.split('|')[0];
        } else {
          return record.imgurls;
        }
      };
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    if (+this.$route.query.addType == 1) {
      this.createForm.resetFields();
      this.page = 1;
      this.size = 20;
    }
    this.getList();
  },
  methods: {
    //跳转到新增或编辑组合页面
    addWhitelist: function addWhitelist(record, key) {
      this.modalVisible = true;
    },
    // 取消删除
    handleCancle: function handleCancle() {
      this.isDeleteVisible = false;
    },
    // 确定删除
    handleSubmit: function handleSubmit() {
      var _this = this;
      this.deleting = true;
      WhitelistApi.Delete({
        userid: this.operateId
      }).then(function (res) {
        _this.size = 20;
        _this.page = 1;
        _this.toast("操作成功", "success");
        _this.isDeleteVisible = false;
        _this.deleting = false;
        _this.getList();
      }).catch(function (err) {
        _this.isDeleteVisible = false;
        _this.deleting = false;
      });
    },
    handleDelete: function handleDelete(val, username) {
      this.tipsDesc = "\u786E\u5B9A\u5220\u9664\u3010".concat(username, "\u3011\u7684\u8BB0\u5F55\uFF1F");
      this.isDeleteVisible = true;
      this.operateId = val;
    },
    disabledDate: function disabledDate(current) {
      return "";
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          Object.keys(values).forEach(function (key) {
            if (!values[key]) delete values[key];
          });
          var params = _objectSpread(_objectSpread({}, values), {}, {
            size: _this2.size,
            page: _this2.page
          });
          WhitelistApi.findpage(params).then(function (res) {
            _this2.loading = false;
            _this2.dataSource = res.list;
            _this2.total = res.total;
          }).catch(function () {
            _this2.dataSource = [];
          }).finally(function (e) {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
      // this.createForm.resetFields();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAddCancle: function handleAddCancle() {
      this.modalVisible = false;
    },
    handleAddSubmit: function handleAddSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    handleStatus: function handleStatus(userid, tostatus) {
      var _this3 = this;
      if (!userid) {
        this.toast('异常数据', 'error');
        return;
      }
      this.loading = true;
      var action = tostatus == 1 ? 'Enable' : 'Disable';
      WhitelistApi[action]({
        userid: userid
      }).then(function (res) {
        _this3.getList();
      });
    }
  }
};