var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      form: _vm.editorForm,
      layout: "horizontal",
      labelCol: {
        style: "text-align:right; margin-right:12px;"
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "用户名称"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["user", {
        rules: [{
          required: true,
          message: "请输入用户名!"
        }]
      }],
      expression: "[\n                    'user',\n                    {\n                      rules: [\n                        { required: true, message: '请输入用户名!' },\n                      ],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "show-search": "",
      allowClear: true,
      placeholder: "Select",
      "filter-option": false,
      "not-found-content": _vm.fetching ? undefined : "No Data",
      size: "large",
      "option-label-prop": "title",
      labelInValue: true,
      defaultActiveFirstOption: "false"
    },
    on: {
      search: _vm.onSearch
    }
  }, [_vm.fetching ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.dataSource, function (item) {
    return _c("a-select-option", {
      key: "".concat(item.id),
      attrs: {
        value: "".concat(item.id),
        title: item.username
      }
    }, [_vm._v(" " + _vm._s(item.username) + " ")]);
  })], 2)], 1), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remark", {
        rules: [{
          required: false,
          message: "请输入备注!"
        }]
      }],
      expression: "[\n                    'remark',\n                    {\n                      rules: [\n                        { required: false, message: '请输入备注!' },\n                      ],\n                    },\n                ]"
    }],
    attrs: {
      placeholder: "",
      size: "large",
      autocomplete: "off"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      "text-align": "center",
      "justify-content": "space-between",
      "margin-bottom": "0"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s("提交"))])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };